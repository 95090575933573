var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:font:list:button:edit",
        "destroy-on-close": "",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            {
              staticStyle: { "font-weight": "400" },
              attrs: { "label-width": _vm.labelWidth, label: "名称" },
            },
            [_vm._v(_vm._s(_vm.curRow.title))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sort))]
          ),
          _c("g-title", [_vm._v("正文")]),
          _vm.curRow.cardList
            ? _vm._l(_vm.curRow.cardList, function (one) {
                return _c(
                  "a-form",
                  { key: one.id },
                  [
                    one.type == "DYNAMIC_FORM"
                      ? _c("a-form-item", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("canAddFilter")(one.canAdd)) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _c("a-form-item", { attrs: { label: "字段名称" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(one.dataList[0].name) +
                          "\n        "
                      ),
                    ]),
                    _c("a-form-item", { attrs: { label: "是否必填" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            one.dataList
                              ? one.dataList[0].valueRequired == "NO"
                                ? "否"
                                : "是"
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _c("a-form-item", { attrs: { label: "字段描述" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            one.dataList ? one.dataList[0].description : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _c("a-form-item", { attrs: { label: "录入提示" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            one.dataList ? one.dataList[0].placeholder : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    one.type == "INPUT_NUMBER"
                      ? _c("a-form-item", { attrs: { label: "小数位数" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.dataList ? one.dataList[0].selectCount : ""
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "DYNAMIC_FORM"
                      ? _c("a-form-item", { attrs: { label: "显示方式" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.viewMode == "HORIZONTAL"
                                  ? "横排显示"
                                  : "竖排显示"
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "DYNAMIC_SELECT"
                      ? _c("a-form-item", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.additional == "1"
                                  ? "允许新增"
                                  : "不允许新增"
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "DYNAMIC_FORM" && one.dataList
                      ? _vm._l(one.dataList, function (item, itemIndex) {
                          return _c(
                            "div",
                            {
                              key: itemIndex,
                              staticStyle: { "margin-bottom": "-12px" },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "子选项" } },
                                [
                                  _c("span", [_vm._v(_vm._s(item.value))]),
                                  _c(
                                    "span",
                                    {
                                      style: item.value
                                        ? "margin-left:20px;"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.type == "INPUT"
                                            ? "文本录入"
                                            : item.type == "SELECT"
                                            ? "下拉选项"
                                            : "标签"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  style:
                                    item.type != "INPUT"
                                      ? "margin-bottom:36px;margin-top:24px;"
                                      : "height: 11px;",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "0px" } },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          item.type == "TAG"
                                            ? _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "134px",
                                                    "padding-right": "66px",
                                                  },
                                                  attrs: { span: 16 },
                                                },
                                                [
                                                  _vm._l(
                                                    item.options,
                                                    function (i, index) {
                                                      return [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            key: index,
                                                            staticStyle: {
                                                              "margin-bottom":
                                                                "8px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(i.label)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "134px",
                                                    "padding-right": "66px",
                                                    "margin-top": "-8px",
                                                  },
                                                  attrs: { span: 16 },
                                                },
                                                _vm._l(
                                                  item.options,
                                                  function (i, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "ant-badge-status-dot ant-badge-status-processing",
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(i.label)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              item.options &&
                              item.type != "RADIO" &&
                              item.type != "SELECT"
                                ? _c(
                                    "a-form-item",
                                    { attrs: { label: "选项个数" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.selectCount) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.type == "DYNAMIC_SELECT"
                                ? _c("a-form-item", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          item.additional == "1"
                                            ? "允许新增"
                                            : "不允许新增"
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              item.type == "DYNAMIC_SELECT"
                                ? _c("a-form-item", {
                                    attrs: { label: "设置数据源" },
                                  })
                                : _vm._e(),
                              item.type == "DYNAMIC_SELECT"
                                ? _c("a-form-item", {
                                    attrs: { label: "显示类型" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    one.type == "FILE_UPLOAD"
                      ? _c("a-form-item", { attrs: { label: "上传形式" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.dataList[0].additional == "1"
                                  ? "本地上传"
                                  : "剪切板上传"
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "SELECT" ||
                    one.type == "TAG" ||
                    one.type == "RADIO" ||
                    one.type == "FILE_UPLOAD"
                      ? _c(
                          "a-form-item",
                          {
                            class:
                              one.type == "SELECT" ||
                              one.type == "RADIO" ||
                              one.type == "FILE_UPLOAD"
                                ? "option-item"
                                : "option-item option-tag-item",
                            staticStyle: { "padding-right": "66px" },
                            attrs: {
                              label:
                                one.type == "SELECT" || one.type == "RADIO"
                                  ? "选项内容"
                                  : one.type == "FILE_UPLOAD"
                                  ? "图片格式"
                                  : "标签内容",
                            },
                          },
                          [
                            one.dataList
                              ? [
                                  _vm._l(
                                    one.dataList[0].options,
                                    function (i, idx) {
                                      return [
                                        one.type == "TAG"
                                          ? _c(
                                              "a-tag",
                                              {
                                                key: idx,
                                                staticStyle: {
                                                  "margin-bottom": "8px",
                                                },
                                              },
                                              [_vm._v(_vm._s(i.label))]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                key: idx,
                                                style:
                                                  idx == 0
                                                    ? ""
                                                    : "margin-top:-15px;",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "ant-badge-status-dot ant-badge-status-processing",
                                                }),
                                                _c("span", [
                                                  _vm._v(_vm._s(i.label)),
                                                ]),
                                              ]
                                            ),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    one.type == "FILE_UPLOAD" && one.dataList[0].attach == "NO"
                      ? _c("a-form-item", { attrs: { label: "最大容量" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(one.dataList[0].selectCount + "MB") +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "FILE_UPLOAD"
                      ? _c("a-form-item", { attrs: { label: "是否裁剪" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.dataList[0].attach == "YES" ? "是" : "否"
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "FILE_UPLOAD"
                      ? _c("a-form-item", { attrs: { label: "裁剪形状" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                one.dataList[0].extra == "y"
                                  ? "圆形"
                                  : one.dataList[0].extra == "c"
                                  ? "尺寸"
                                  : "比例"
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    one.type == "FILE_UPLOAD" &&
                    one.dataList[0].extra == "c" &&
                    one.dataList[0].attach == "YES"
                      ? _c("a-form-item", { attrs: { label: "裁剪尺寸" } }, [
                          _c("span", [_vm._v("宽度（PX）")]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(one.dataList[0].cum))]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v("高度（PX）")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(one.dataList[0].other))]
                          ),
                        ])
                      : _vm._e(),
                    one.type == "FILE_UPLOAD" &&
                    one.dataList[0].extra == "b" &&
                    one.dataList[0].attach == "YES"
                      ? _c("a-form-item", { attrs: { label: "裁剪比例" } }, [
                          _c("span", [_vm._v("宽")]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(one.dataList[0].cum))]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v("高")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(one.dataList[0].other))]
                          ),
                        ])
                      : _vm._e(),
                    one.type != "INPUT_NUMBER" &&
                    one.type != "FILE_UPLOAD" &&
                    one.type != "DYNAMIC_SELECT" &&
                    one.type != "RADIO" &&
                    one.type != "SELECT"
                      ? [
                          one.type == "SELECT" || one.type == "TAG"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "选项个数" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        one.dataList &&
                                          one.dataList[0].selectCount
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "a-form-item",
                                { attrs: { label: "输入框字数" } },
                                [
                                  one.dataList[0].limitRange
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getLimitRange(
                                                one.dataList[0].limitRange
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    one.type == "DYNAMIC_SELECT"
                      ? _c("a-form-item", { attrs: { label: "设置数据源" } })
                      : _vm._e(),
                    one.type == "DYNAMIC_SELECT"
                      ? _c("a-form-item", { attrs: { label: "显示类型" } })
                      : _vm._e(),
                  ],
                  2
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: { label: "名称", prop: "sequenceNo" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sort", $$v)
                      },
                      expression: "formData.sort",
                    },
                  }),
                ],
                1
              ),
              _c("g-title", [_vm._v("正文")]),
              _vm._l(_vm.formData.formItems, function (one, index) {
                return _c("a-form-model-item", {
                  key: index,
                  attrs: { label: one.label, prop: one.prop },
                })
              }),
              _vm.formData.cardList
                ? [
                    _vm._l(_vm.formData.cardList, function (one, index) {
                      return [
                        _c(
                          "div",
                          { key: one.id, staticClass: "edit-form" },
                          [
                            _c(
                              "a-form-item",
                              {
                                staticClass: "description",
                                attrs: { label: "字段名称" },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: one.dataList[0].name,
                                    callback: function ($$v) {
                                      _vm.$set(one.dataList[0], "name", $$v)
                                    },
                                    expression: "one.dataList[0].name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "是否必填" } },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: one.dataList[0].valueRequired,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          one.dataList[0],
                                          "valueRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "one.dataList[0].valueRequired",
                                    },
                                  },
                                  [
                                    _c("a-radio", { attrs: { value: "YES" } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c("a-radio", { attrs: { value: "NO" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              {
                                staticClass: "description",
                                attrs: { label: "字段描述" },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: one.dataList[0].description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        one.dataList[0],
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "one.dataList[0].description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              {
                                staticClass: "description",
                                attrs: { label: "录入提示" },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: one.dataList[0].placeholder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        one.dataList[0],
                                        "placeholder",
                                        $$v
                                      )
                                    },
                                    expression: "one.dataList[0].placeholder",
                                  },
                                }),
                              ],
                              1
                            ),
                            one.type == "INPUT_NUMBER"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "小数位数" },
                                  },
                                  [
                                    _c("a-input-number", {
                                      attrs: { min: 0, placeholder: "请输入" },
                                      model: {
                                        value: one.dataList[0].selectCount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            one.dataList[0],
                                            "selectCount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "one.dataList[0].selectCount",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_FORM"
                              ? _c(
                                  "a-form-item",
                                  { attrs: { label: "显示方式" } },
                                  [
                                    _c(
                                      "a-radio-group",
                                      {
                                        model: {
                                          value: one.viewMode,
                                          callback: function ($$v) {
                                            _vm.$set(one, "viewMode", $$v)
                                          },
                                          expression: "one.viewMode",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "HORIZONTAL" } },
                                          [_vm._v("横排显示")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "VERTICAL" } },
                                          [_vm._v("竖排显示")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_FORM"
                              ? _c(
                                  "a-checkbox",
                                  {
                                    staticStyle: { "margin-bottom": "24px" },
                                    model: {
                                      value: one.canAdd,
                                      callback: function ($$v) {
                                        _vm.$set(one, "canAdd", $$v)
                                      },
                                      expression: "one.canAdd",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              填写时是否允许动态加行\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_SELECT"
                              ? _c(
                                  "a-checkbox",
                                  {
                                    staticStyle: { "margin-bottom": "24px" },
                                    on: {
                                      change: (e) => {
                                        _vm.checkChange(e, one)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              填写时是否允许动态新增\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_FORM"
                              ? _vm._l(one.dataList, function (item, i) {
                                  return _c(
                                    "div",
                                    { key: i },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          staticClass: "dt-form",
                                          attrs: { label: "子选项" },
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            [
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "flex",
                                                    flex: "1",
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value: item.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.value",
                                                    },
                                                  }),
                                                  _c(
                                                    "a-select",
                                                    {
                                                      ref: "select",
                                                      refInFor: true,
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: { allowClear: "" },
                                                      on: {
                                                        change: (value) =>
                                                          _vm.changeSelect(
                                                            value,
                                                            item
                                                          ),
                                                      },
                                                      model: {
                                                        value: item.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.type",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "INPUT",
                                                          },
                                                        },
                                                        [_vm._v("文本录入")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "SELECT",
                                                          },
                                                        },
                                                        [_vm._v("下拉选项")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "RADIO",
                                                          },
                                                        },
                                                        [_vm._v("横排单选")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "TAG",
                                                          },
                                                        },
                                                        [_vm._v("标签")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value:
                                                              "DYNAMIC_SELECT",
                                                          },
                                                        },
                                                        [_vm._v("动态选择")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value:
                                                              "FILE_UPLOAD",
                                                          },
                                                        },
                                                        [_vm._v("图片上传")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "min-width": "45px",
                                                    "margin-left": "10px",
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    attrs: {
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "minus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          one.dataList,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    staticStyle: {
                                                      "margin-left": "8px",
                                                    },
                                                    attrs: {
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "plus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItem(
                                                          one.dataList,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      item.type == "SELECT" ||
                                      item.type == "RADIO"
                                        ? [
                                            _c(
                                              "a-form-item",
                                              { attrs: { label: "选项内容" } },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          on: {
                                                            change: (e) => {
                                                              _vm.inputSearchChange(
                                                                e,
                                                                i,
                                                                one.dataList
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.optionsPushValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "optionsPushValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.optionsPushValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                          "min-width": "45px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            staticStyle: {
                                                              "max-width":
                                                                "56px",
                                                              display: "flex",
                                                              "justify-content":
                                                                "center",
                                                              "align-items":
                                                                "center",
                                                              position:
                                                                "relative",
                                                              top: "3px",
                                                            },
                                                            attrs: {
                                                              icon: "plus",
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addOptions(
                                                                  item.options,
                                                                  item.optionsPushValue,
                                                                  item,
                                                                  i,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("添加")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            !item.isSeach
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-tag",
                                                                    {
                                                                      staticClass:
                                                                        "close-tag",
                                                                      attrs: {
                                                                        closable:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        dblclick:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            _vm.tagClick(
                                                                              e,
                                                                              value.label,
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                        close:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.closeTag(
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          value.label
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _vm.isSeachItem(
                                                                    index
                                                                  )
                                                                    ? _c(
                                                                        "a-tag",
                                                                        {
                                                                          staticClass:
                                                                            "close-tag",
                                                                          attrs:
                                                                            {
                                                                              closable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            dblclick:
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                _vm.tagClick(
                                                                                  e,
                                                                                  value.label,
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                            close:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.closeTag(
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              value.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                          ]
                                        : _vm._e(),
                                      item.type == "TAG"
                                        ? [
                                            _c(
                                              "a-form-item",
                                              { attrs: { label: "标签" } },
                                              [
                                                _c("tag-drag-select", {
                                                  staticStyle: {
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    isPm: true,
                                                    "scope-id": i,
                                                    "prop-data-list":
                                                      item.options || [],
                                                  },
                                                  on: {
                                                    change: (tagList) =>
                                                      _vm.wordsFormChange(
                                                        tagList,
                                                        item.options,
                                                        index,
                                                        i
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                      item.type == "DYNAMIC_SELECT"
                                        ? [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "设置数据源",
                                                    },
                                                  },
                                                  [
                                                    _c("a-select", {
                                                      ref: "select",
                                                      refInFor: true,
                                                      attrs: {
                                                        mode: "multiple",
                                                        allowClear: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "显示类型",
                                                    },
                                                  },
                                                  [
                                                    _c("a-select", {
                                                      ref: "select",
                                                      refInFor: true,
                                                      attrs: { allowClear: "" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                      item.options &&
                                      item.type != "RADIO" &&
                                      item.type != "SELECT"
                                        ? _c(
                                            "a-form-item",
                                            {
                                              staticClass: "description",
                                              attrs: { label: "选项个数" },
                                            },
                                            [
                                              _c("a-input-number", {
                                                attrs: { min: 0 },
                                                model: {
                                                  value: item.selectCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "selectCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.selectCount",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.type == "DYNAMIC_SELECT"
                                        ? _c(
                                            "a-checkbox",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "24px",
                                              },
                                              on: {
                                                change: (e) => {
                                                  _vm.checkChange(e, item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  填写时是否允许动态新增\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.type == "FILE_UPLOAD"
                                        ? _c(
                                            "a-form-item",
                                            {
                                              staticClass: "description",
                                              attrs: { label: "上传形式" },
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  model: {
                                                    value: item.additional,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "additional",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.additional",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-radio",
                                                    { attrs: { value: "1" } },
                                                    [_vm._v("本地上传")]
                                                  ),
                                                  _c(
                                                    "a-radio",
                                                    { attrs: { value: "2" } },
                                                    [_vm._v("剪切板上传")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.type == "FILE_UPLOAD"
                                        ? [
                                            _c(
                                              "a-form-item",
                                              { attrs: { label: "图片格式" } },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          on: {
                                                            change: (e) => {
                                                              _vm.inputSearchChange(
                                                                e,
                                                                i,
                                                                one.dataList
                                                              )
                                                            },
                                                            blur: (e) => {
                                                              _vm.inputBlur(e)
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.optionsPushValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "optionsPushValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.optionsPushValue",
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                              "margin-top":
                                                                "-1vh",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                ref: "visDiv",
                                                                refInFor: true,
                                                                staticStyle: {
                                                                  color:
                                                                    "#f5222d",
                                                                  display:
                                                                    "none",
                                                                  "margin-left":
                                                                    "5px",
                                                                  "font-size":
                                                                    "12px",
                                                                  position:
                                                                    "absolute",
                                                                  top: "0",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            请输入正确图片格式!\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                          "min-width": "45px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            staticStyle: {
                                                              "max-width":
                                                                "56px",
                                                              display: "flex",
                                                              "justify-content":
                                                                "center",
                                                              "align-items":
                                                                "center",
                                                              position:
                                                                "relative",
                                                              top: "3px",
                                                            },
                                                            attrs: {
                                                              icon: "plus",
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addOptionsImg(
                                                                  item.options,
                                                                  item.optionsPushValue,
                                                                  item,
                                                                  i,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("添加")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            !item.isSeach
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-tag",
                                                                    {
                                                                      staticClass:
                                                                        "close-tag",
                                                                      attrs: {
                                                                        closable:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        dblclick:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            _vm.tagClick(
                                                                              e,
                                                                              value.label,
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                        close:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.closeTag(
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          value.label
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _vm.isSeachItem(
                                                                    index
                                                                  )
                                                                    ? _c(
                                                                        "a-tag",
                                                                        {
                                                                          staticClass:
                                                                            "close-tag",
                                                                          attrs:
                                                                            {
                                                                              closable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            dblclick:
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                _vm.tagClick(
                                                                                  e,
                                                                                  value.label,
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                            close:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.closeTag(
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              value.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                          ]
                                        : _vm._e(),
                                      item.type == "FILE_UPLOAD"
                                        ? [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    staticClass: "description",
                                                    attrs: {
                                                      label: "是否裁剪",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a-radio-group",
                                                      {
                                                        model: {
                                                          value: item.attach,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "attach",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.attach",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-radio",
                                                          {
                                                            attrs: {
                                                              value: "YES",
                                                            },
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _c(
                                                          "a-radio",
                                                          {
                                                            attrs: {
                                                              value: "NO",
                                                            },
                                                          },
                                                          [_vm._v("否")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                !item.attach
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        staticClass:
                                                          "description",
                                                        attrs: {
                                                          label:
                                                            "最大容量（MB）",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input-number", {
                                                          attrs: {
                                                            min: 0,
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          model: {
                                                            value:
                                                              item.description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "description",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.description",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.cj
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        staticClass:
                                                          "description",
                                                        attrs: {
                                                          label: "裁剪形状",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-select",
                                                          {
                                                            ref: "select",
                                                            refInFor: true,
                                                            attrs: {
                                                              allowClear: "",
                                                            },
                                                            model: {
                                                              value: item.xz,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "xz",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.xz",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-select-option",
                                                              {
                                                                attrs: {
                                                                  value: "y",
                                                                },
                                                              },
                                                              [_vm._v("圆形")]
                                                            ),
                                                            _c(
                                                              "a-select-option",
                                                              {
                                                                attrs: {
                                                                  value: "c",
                                                                },
                                                              },
                                                              [_vm._v("尺寸")]
                                                            ),
                                                            _c(
                                                              "a-select-option",
                                                              {
                                                                attrs: {
                                                                  value: "b",
                                                                },
                                                              },
                                                              [_vm._v("比例")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.xz == "c" && item.cj
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        staticClass:
                                                          "description-two",
                                                        attrs: {
                                                          label: "裁剪尺寸",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              width: "48%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "180px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "宽度（PX）"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  placeholder:
                                                                    "请输入",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.description",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              width: "48%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "180px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "高度（PX）"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  placeholder:
                                                                    "请输入",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.description",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.xz == "b" && _vm.oitem.cj
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        staticClass:
                                                          "description-two",
                                                        attrs: {
                                                          label: "固定比例",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              width: "48%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "180px",
                                                                },
                                                              },
                                                              [_vm._v("宽")]
                                                            ),
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  placeholder:
                                                                    "请输入",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.description",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              width: "48%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "180px",
                                                                },
                                                              },
                                                              [_vm._v("高")]
                                                            ),
                                                            _c(
                                                              "a-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  placeholder:
                                                                    "请输入",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.description",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                })
                              : _vm._e(),
                            one.type == "SELECT" || one.type == "RADIO"
                              ? [
                                  _c(
                                    "a-form-item",
                                    { attrs: { label: "选项内容" } },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { type: "flex" } },
                                        [
                                          _c(
                                            "a-col",
                                            { staticStyle: { flex: "1" } },
                                            [
                                              _c("a-input", {
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    one.dataList[0]
                                                      .optionsPushValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      one.dataList[0],
                                                      "optionsPushValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "one.dataList[0].optionsPushValue",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "max-width": "56px",
                                                    display: "flex",
                                                    "justify-content": "center",
                                                    "align-items": "center",
                                                    position: "relative",
                                                    top: "3px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    icon: "plus",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOptions(
                                                        one.dataList[0].options,
                                                        one.dataList[0]
                                                          .optionsPushValue,
                                                        one.dataList[0],
                                                        0,
                                                        one.dataList
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "添加\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !one.dataList[0].isSeach
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            staticClass:
                                                              "close-tag",
                                                            attrs: {
                                                              closable: "",
                                                            },
                                                            on: {
                                                              dblclick: (e) => {
                                                                _vm.tagClick(
                                                                  e,
                                                                  value.label,
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                              close: function (
                                                                $event
                                                              ) {
                                                                return _vm.closeTag(
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                value.label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _vm.isSeachItem(index)
                                                          ? _c(
                                                              "a-tag",
                                                              {
                                                                staticClass:
                                                                  "close-tag",
                                                                attrs: {
                                                                  closable: "",
                                                                },
                                                                on: {
                                                                  dblclick: (
                                                                    e
                                                                  ) => {
                                                                    _vm.tagClick(
                                                                      e,
                                                                      value.label,
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    )
                                                                  },
                                                                  close: () =>
                                                                    _vm.closeTag(
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    value.label
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                ]
                              : _vm._e(),
                            one.type == "TAG"
                              ? _c(
                                  "a-form-item",
                                  { attrs: { label: "标签内容" } },
                                  [
                                    _c("tag-drag-select", {
                                      attrs: {
                                        isPm: true,
                                        "scope-id": 0,
                                        "prop-data-list":
                                          one.dataList[0].options || [],
                                      },
                                      on: {
                                        change: (tagList) =>
                                          _vm.wordsChange(
                                            tagList,
                                            one.dataList[0].options,
                                            index
                                          ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type != "FILE_UPLOAD" &&
                            one.type != "DYNAMIC_SELECT" &&
                            one.type != "RADIO" &&
                            one.type != "DYNAMIC_FORM" &&
                            one.type != "SELECT"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: {
                                      label:
                                        one.type == "SELECT" ||
                                        one.type == "TAG"
                                          ? "选项个数"
                                          : "输入框字数",
                                    },
                                  },
                                  [
                                    one.type == "SELECT" || one.type == "TAG"
                                      ? _c("a-input-number", {
                                          attrs: { min: 0 },
                                          model: {
                                            value: one.dataList[0].selectCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "selectCount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "one.dataList[0].selectCount",
                                          },
                                        })
                                      : _c("edit-range-select", {
                                          attrs: {
                                            formData:
                                              one.dataList[0].limitRange,
                                            rangeKey: "type",
                                            valueKey: "value",
                                            needUnit: false,
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_SELECT"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "设置数据源" },
                                  },
                                  [
                                    _c("a-select", {
                                      ref: "select",
                                      refInFor: true,
                                      attrs: {
                                        mode: "multiple",
                                        allowClear: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "DYNAMIC_SELECT"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "显示类型" },
                                  },
                                  [
                                    _c("a-select", {
                                      ref: "select",
                                      refInFor: true,
                                      attrs: { allowClear: "" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "上传形式" },
                                  },
                                  [
                                    _c(
                                      "a-radio-group",
                                      {
                                        model: {
                                          value: one.dataList[0].additional,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              one.dataList[0],
                                              "additional",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "one.dataList[0].additional",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "1" } },
                                          [_vm._v("本地上传")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "2" } },
                                          [_vm._v("剪切板上传")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD"
                              ? [
                                  _c(
                                    "a-form-item",
                                    { attrs: { label: "图片格式" } },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { type: "flex" } },
                                        [
                                          _c(
                                            "a-col",
                                            { staticStyle: { flex: "1" } },
                                            [
                                              _c("a-input", {
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                on: { blur: _vm.inputBlur },
                                                model: {
                                                  value:
                                                    one.dataList[0]
                                                      .optionsPushValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      one.dataList[0],
                                                      "optionsPushValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "one.dataList[0].optionsPushValue",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                    "margin-top": "-1vh",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      ref: "visDiv",
                                                      refInFor: true,
                                                      staticStyle: {
                                                        color: "#f5222d",
                                                        display: "none",
                                                        "margin-left": "5px",
                                                        "font-size": "12px",
                                                        position: "absolute",
                                                        top: "0",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        请输入正确图片格式!\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "max-width": "56px",
                                                    display: "flex",
                                                    "justify-content": "center",
                                                    "align-items": "center",
                                                    position: "relative",
                                                    top: "3px",
                                                  },
                                                  attrs: {
                                                    icon: "plus",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOptionsImg(
                                                        one.dataList[0].options,
                                                        one.dataList[0]
                                                          .optionsPushValue,
                                                        one.dataList[0],
                                                        0,
                                                        one.dataList
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "添加\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !one.dataList[0].isSeach
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            staticClass:
                                                              "close-tag",
                                                            attrs: {
                                                              closable: "",
                                                            },
                                                            on: {
                                                              dblclick: (e) => {
                                                                _vm.tagClick(
                                                                  e,
                                                                  value.label,
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                              close: function (
                                                                $event
                                                              ) {
                                                                return _vm.closeTag(
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                value.label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _vm.isSeachItem(index)
                                                          ? _c(
                                                              "a-tag",
                                                              {
                                                                staticClass:
                                                                  "close-tag",
                                                                attrs: {
                                                                  closable: "",
                                                                },
                                                                on: {
                                                                  dblclick: (
                                                                    e
                                                                  ) => {
                                                                    _vm.tagClick(
                                                                      e,
                                                                      value.label,
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    )
                                                                  },
                                                                  close: () =>
                                                                    _vm.closeTag(
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    value.label
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                ]
                              : _vm._e(),
                            one.type == "FILE_UPLOAD" &&
                            one.dataList[0].attach == "NO"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "最大容量（MB）" },
                                  },
                                  [
                                    _c("a-input-number", {
                                      attrs: { min: 0, placeholder: "请输入" },
                                      model: {
                                        value: one.dataList[0].selectCount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            one.dataList[0],
                                            "selectCount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "one.dataList[0].selectCount",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "是否裁剪" },
                                  },
                                  [
                                    _c(
                                      "a-radio-group",
                                      {
                                        model: {
                                          value: one.dataList[0].attach,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              one.dataList[0],
                                              "attach",
                                              $$v
                                            )
                                          },
                                          expression: "one.dataList[0].attach",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "YES" } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "NO" } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD" &&
                            one.dataList[0].attach == "YES"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: { label: "裁剪形状" },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        ref: "select",
                                        refInFor: true,
                                        attrs: { allowClear: "" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.extraChange(
                                              one.dataList[0]
                                            )
                                          },
                                        },
                                        model: {
                                          value: one.dataList[0].extra,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              one.dataList[0],
                                              "extra",
                                              $$v
                                            )
                                          },
                                          expression: "one.dataList[0].extra",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "y" } },
                                          [_vm._v("圆形")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "c" } },
                                          [_vm._v("尺寸")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "b" } },
                                          [_vm._v("比例")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD" &&
                            one.dataList[0].extra == "c" &&
                            one.dataList[0].attach == "YES"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description-two",
                                    attrs: { label: "裁剪尺寸" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          width: "48%",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "180px" } },
                                          [_vm._v("宽度（PX）")]
                                        ),
                                        _c("a-input-number", {
                                          attrs: {
                                            min: 0,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: one.dataList[0].cum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "cum",
                                                $$v
                                              )
                                            },
                                            expression: "one.dataList[0].cum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          width: "48%",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "180px" } },
                                          [_vm._v("高度（PX）")]
                                        ),
                                        _c("a-input-number", {
                                          attrs: {
                                            min: 0,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: one.dataList[0].other,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "other",
                                                $$v
                                              )
                                            },
                                            expression: "one.dataList[0].other",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            one.type == "FILE_UPLOAD" &&
                            one.dataList[0].extra == "b" &&
                            one.dataList[0].attach == "YES"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description-two",
                                    attrs: { label: "固定比例" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          width: "48%",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "50px" } },
                                          [_vm._v("宽")]
                                        ),
                                        _c("a-input-number", {
                                          attrs: {
                                            min: 0,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: one.dataList[0].cum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "cum",
                                                $$v
                                              )
                                            },
                                            expression: "one.dataList[0].cum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          width: "48%",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "50px" } },
                                          [_vm._v("高")]
                                        ),
                                        _c("a-input-number", {
                                          attrs: {
                                            min: 0,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: one.dataList[0].other,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "other",
                                                $$v
                                              )
                                            },
                                            expression: "one.dataList[0].other",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
              _c(
                "a-form-model-item",
                { staticClass: "add-item-form-item" },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择组件", value: "请选择组件" },
                      on: { change: _vm.formSelectChange },
                    },
                    _vm._l(
                      [
                        { value: "FILE_UPLOAD", label: "图片上传" },
                        { value: "DYNAMIC_FORM", label: "动态表单" },
                        { value: "DYNAMIC_SELECT", label: "动态选择" },
                        { value: "INPUT", label: "单行文本" },
                        { value: "TEXTAREA", label: "多行文本" },
                        { value: "TAG", label: "标签" },
                        { value: "SELECT", label: "下拉选择" },
                        { value: "RADIO", label: "横排单选" },
                        { value: "INPUT_NUMBER", label: "数字输入框" },
                      ],
                      function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.value, attrs: { value: item.value } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }